"use client";
import { RecoilRoot } from "recoil";

interface RecoilRootWrapperProps {
  children: React.ReactNode;
}

const RecoilProvider = ({ children }: RecoilRootWrapperProps) => {
  return <RecoilRoot>{children}</RecoilRoot>;
};

export default RecoilProvider;
