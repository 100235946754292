import { atom } from "recoil";

export type DarkModeState = {
  theme: "dark" | "light" | "default";
  systemTheme: "dark" | "light" | "not-ready";
};

const initialState: DarkModeState = {
  theme: "default",
  systemTheme: "not-ready",
};

const themeState = atom({
  key: "themeState",
  default: initialState,
});

export { themeState };
