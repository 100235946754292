import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getCookie(name: string) {
  if (typeof document !== "undefined") {
    return document.cookie
      .match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")
      ?.pop();
  }
}
