"use client";

import * as React from "react";
import { Button } from "./ui/button";
import { useRecoilState } from "recoil";
import { themeState } from "@/libs/recoil/theme";
import { useTheme } from "next-themes";
import Sun from "@/assets/svg/sun.svg";
import Moon from "@/assets/svg/half-moon.svg";

export function DarkModeToggle() {
  const { theme, setTheme, resolvedTheme } = useTheme();
  const [darkModeTheme, setRecoilTheme] = useRecoilState(themeState);

  const save = (value: "light" | "dark") => {
    setRecoilTheme({
      ...darkModeTheme,
      theme: value,
    });

    const isSystemDark = resolvedTheme === "dark" && value === "dark";

    setTheme(isSystemDark ? "light" : value); // For CSR
    document.cookie = `theme=${value}; path=/;`; // For SSR
  };

  const toggle = () => {
    if (theme === "dark") {
      save("light");
    } else {
      save("dark");
    }
  };

  return (
    <Button variant="ghost" size="icon" onClick={toggle}>
      <Sun className="dark:fill-white  absolute rotate-90 scale-0 dark:rotate-0 dark:scale-100" />
      <Moon className="dark:fill-white dark:-rotate-90 dark:scale-0" />
    </Button>
  );
}
