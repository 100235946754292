import { useRecoilState } from "recoil";
import { themeState } from "../recoil/theme";
import { useEffect } from "react";
import { useTheme } from "next-themes";

const useThemeEffect = () => {
  const { setTheme } = useTheme();
  const [theme, setSystemTheme] = useRecoilState(themeState);

  // 최초 앱 실행시 시스템 테마 체크
  useEffect(() => {
    const systemPrefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    setSystemTheme({
      ...theme,
      systemTheme: systemPrefersDark ? "dark" : "light",
    });

    console.log("systemPrefersDark", systemPrefersDark);

    setTheme(systemPrefersDark ? "a" : "b"); // For CSR
    document.cookie = `theme=${systemPrefersDark ? "dark" : "light"}; path=/;`; // For SSR
  }, []);

  return theme;
};

export default useThemeEffect;
